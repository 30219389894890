<template>
  <div>
    <validation-observer
      ref="form"
      v-slot="{invalid}"
    >
      <b-card v-if="!isDataLoaded">
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </b-card>

      <b-form
        v-else
        novalidate
        @submit.prevent="onSubmit"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card>
              <b-row>

                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    vid="type"
                    :name="$t('labels.type')"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('labels.type')"
                    >
                      <v-select
                        v-model="form.type"
                        :dir="$store.state.appConfig.layout.direction"
                        label="label"
                        :options="types"
                        :reduce="item => item.value"
                        :placeholder="$t('labels.type')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12">
                  <LocaleTabs
                    :errors="formErrors"
                    name="content"
                  >
                    <template
                      v-for="(i,k,idx) in languages"
                      :slot="`lang${idx}`"
                      slot-scope="slotScope"
                    >
                      <ValidationProvider
                        :key="idx"
                        v-slot="{ errors }"
                        :class="slotScope.selectedTab!==idx?'hidden':''"
                        :vid="`content.${k}`"
                        :name="$t('general.field_lang', { field: $t('labels.content'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        :rules="k === 'en' ? '':'required'"
                      >
                        <b-form-group
                          :label="$t('general.field_lang', { field: $t('labels.content'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                        >
                          <quill-editor
                            v-model="form.content[k]"
                            :options="snowOption"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </ValidationProvider>
                    </template>
                  </LocaleTabs>
                </b-col>
                <b-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    vid="image"
                    :name="$t('labels.logo')"
                    rules="required"
                  >
                    <ImagePreview
                      v-model="logo"
                      :label="$t('labels.logo')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12 text-right">
                  <LoadingButton />
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    {{ $t('labels.reset') }}
                  </b-button>
                </b-col>

              </b-row>

            </b-card>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded: false,
      types: [],

      snowOption: {
        theme: 'snow',
      },
      logo: null,

      form: {
        content: {
          en: null,
          ar: null,
        },
        type: null,
        logo: null,
      },
    }
  },
  watch: {
    logo(val) {
      if (val) {
        this.form.logo = val
      } else {
        this.form.logo = 'delete'
      }
    },

  },

  created() {
    this.getType()

    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getType() {
      this.axios.get('/lists/usageTerm/types')
        .then(res => {
          this.types = res.data
        })
    },

    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            content: data.content,
            type: data.type,
          }
          this.logo = data.logo
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.logo instanceof File)) {
        delete $form.logo
      }
      return $form
    },

  },
}
</script>
